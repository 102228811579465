import "../styles/globals.css";
import type { AppProps } from "next/app";
import { DM_Sans } from "@next/font/google";
import Head from "next/head";
import { AnimatePresence } from "framer-motion";
import Script from "next/script";

const GOOGLE_ANALYTICS_KEY = 'G-1XJXRNG060'

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <>
      <Head>
        <title>Dear Nipple</title>
        <meta name='description' content='Dear Nipple app.'/>
        <link rel="icon" href="/favicons/favicon-32x32.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192"  href="/favicons/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#ffffff"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta property="og:url" content="dearnipple.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Dear Nipple"
        />
        <meta name="twitter:card" content="Dear Nipple is an invitation to reconnect with our original abundance, pleasure and love. " />
        <meta
          property="og:description"
          content="Dear Nipple is an invitation to reconnect with our original abundance, pleasure and love. "
        />
        <meta property="og:image" content={"/jewerly-landing.png"} />
      </Head>
      <main className="text-lg">
        {/* <!-- Google tag (gtag.js) --> */}
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_KEY}`}  strategy="afterInteractive" />
        <Script id="google-analytics" strategy="afterInteractive">
          {` window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ANALYTICS_KEY}');
          `}
        </Script>
        <AnimatePresence
          mode="wait"
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <Component {...pageProps} key={router.pathname} />
        </AnimatePresence>
      </main>
    </>
  );
}
